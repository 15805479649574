// myComponent.js

import BubbleUI from "react-bubble-ui";
import "react-bubble-ui/dist/index.css";
import "./Bubble.css";

export default function Bubble(props) {

    console.log(window.innerHeight);
    console.log(window.innerWidth);

	const options = {
		size: 120,
		minSize: 20,
		gutter: 8,
		provideProps: true,
		numCols: 6,
		fringeWidth: 200,
		yRadius: 130,
		xRadius: 220,
		cornerRadius: 50,
		showGuides: false,
		compact: true,
		gravitation: 5
	}

	const children = props.skills.map((skill, i) => {
		 //return <Child data={skill} className="child" key={i}/>
         return (
            <div className={"child " + skill.class} key={i}>
                <h3>{skill.name}</h3>
                <h4>{skill.length}</h4>
            </div>
         )
    });

	return (<BubbleUI options={options} className="myBubbleUI">
		{children}
	</BubbleUI>)
};