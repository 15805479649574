import React from "react";
import "./ProjectPage.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faX } from '@fortawesome/free-solid-svg-icons'
import { Carousel } from 'react-bootstrap';


function ProjectPage ( { project } ) {

    let filterList = project.filters.map(function(filter, i){
        return <div className="badge"><p className="card-text"><small className="text-muted" key={i}>{ filter }</small></p></div>;
        });

    let mediaList = project.media.map(function(image, i){
        return <Carousel.Item>
            <img
            className="d-block w-100"
            src={image}
            alt="..."
            key={i}
            />
        </Carousel.Item>;    
    });

  return (
    <div id="project-div">
        <div className="raised-card">

            <div id="close-icon">
                <Link to='/projects' style={{ textDecoration: 'none' }}>
                    <FontAwesomeIcon className="icon" icon={faX} size="2x"/>
                </Link>
            </div>

            <div style={{ justifyContent:"center", display:"flex" }}>
                <Carousel id="carousel" variant="dark">
                    { mediaList }
                </Carousel>
            </div>

            <h1 className="text-center lucida off-white mt-4">{ project.title }</h1>

            { project.createDate && <p class="project-date"><small className="text-muted">Created: {project.createDate}</small></p> }
            { project.createDate && <p class="project-date"><small className="text-muted">Last Updated: {project.updateDate}</small></p> }

            <div style={{display: "flex", gap:"10px", flexFlow:"wrap", justifyContent:"center", margin:"5px"}}>
                { filterList }
            </div>

            <p style={{paddingTop:"20px", paddingBottom:"20px"}}>{ project.longDescription }</p>

            
            <div class="repo-div">
                {
                project.repo ?
                <a href={project.repo} style={{textDecoration:"none"}}>
                    <div class="repo-link">View repository</div>
                </a>
                :
                <p className="off-white repo-link">Private repository</p>
                }
            </div>

        </div>
    </div>
  );
}

export default ProjectPage;
