import React from "react";
import './Projects.css';
import ProjectHub from "./ProjectHub.js";
import ProjectPage from "./ProjectPage";
import { Route , Routes } from 'react-router-dom';
import ErrorPage from "../ErrorPage";
import Sizes from "./Enums.js";


function Projects() {

  const projects = [
    {
      title: "Playlist Comparision",
      link: "playlist-comparision",
      size: Sizes.Large,
      filters: ["API", "Python"],
      thumbnail: "/images/projects/spotify.png",
      media: [],
      shortDescription: "Given two Spotify playlists, create a venn diagram of their songs. Other features include, finding a song's statistics, and tagging songs for improved playlist customization.",
      longDescription: `Using the Python Spotify API, spotipy, create 3 columns representing a venn diagram.
      The user can move songs between the columns to add or remove them from a certain playlist. The user can
      also search a song to find the song's statistics according to the Spotify API. The user will also be
      able to tag songs to make playlists with logical expressions. An example of a playlist's tagged
      expression could be, ((Pop || speed_level > 5) && not EDM). This is
      mainly written in Python and is hosted on a website using Flask.`
    },
    {
      title: "Securing Residential IoT Networks Through Intent-Based Encryption and Categorization",
      link: "mqp",
      size: Sizes.Large,
      filters: ["Networks", "School", "Threading", "Cybersecurity"],
      thumbnail: "/images/projects/mqp/Policy Overview.drawio.png",
      media: ["/images/projects/mqp/Policy Overview.drawio.png",
      "/images/projects/mqp/IoT_Residential_Network.png",
      "/images/projects/mqp/Infrastructure.drawio.png",
      "/images/projects/mqp/Database_Schema.drawio.png",
      "/images/projects/mqp/Policy_UML.drawio.png"],
      shortDescription: "My Major Qualifying Project (MQP) was to develop an IDS/IPS system for IoT devices on a residential network.",
      longDescription: "As consumer home automation devices are gaining popularity, more examples exist of these devices being compromised at scale to create large botnets. In this paper, we present Soteria, a specification based IDS/IPS with the aim of reducing the spread and impact of IoT botnets by blocking malicious outbound traffic from a compromised device. We demonstrate Soteria’s ability to block specification-violating network traffic from IoT devices and to alert an IoT device’s manufacturer and owner when the device violates a policy. Furthermore, Soteria’s ability to alert the manufacturer of compromised IoT devices gives manufacturers increased awareness of vulnerabilities being exploited in the wild. We expect that with adoption, Soteria will help limit the spread and impact of IoT botnets. View the rest of the paper in the repo below.",
      repo: "https://digital.wpi.edu/concern/student_works/d791sj759?locale=en"
    },
    {
      title: "Chat Application",
      link: "chat-application",
      size: Sizes.Large,
      // createDate: "September 1st, 2020 (Fake)",
      // updateDate: "December 1st, 2020 (Fake)",
      filters: ["Networks", "School", "Java", "Threading"],
      thumbnail: "/images/projects/chat_icon.jpeg",
      media: [],
      shortDescription: "A multi-threaded chat application with a feature to directly message a user or speak to the entire group.",
      longDescription: "A multi-threaded chat application with a feature to directly message a user or speak to the entire group. Written in Java."
    },
    {
      title: "Hospital Pathfinding App",
      link: "hospital-pathfinding-app",
      size: Sizes.Large,
      filters: ["School", "Java"],
      thumbnail: "/images/projects/Hospital Pathfinding/2D Directions.png",
      media: ["/images/projects/Hospital Pathfinding/2D Directions.png",
      "/images/projects/Hospital Pathfinding/3D Directions.png",
      "/images/projects/Hospital Pathfinding/Admin Menu.png",
      "/images/projects/Hospital Pathfinding/Maintenance Request.png",
      "/images/projects/Hospital Pathfinding/Nearest Bathroom.png",
      "/images/projects/Hospital Pathfinding/Node Editor.png",
      "/images/projects/Hospital Pathfinding/Service Request.png"],
      shortDescription: "Software engineering project of hospital pathfinding kiosk app.",
      longDescription: `Software engineering project of hospital pathfinding kiosk app.
      This kiosk also has many features including finding the nearest restroom, exit, or cafe.
      The user can also create a maintenance request from the kiosk, either as a patient or a staff member.
      The kiosk has speech to text and text to speech.
      This application was written in Java on a team of 10 software engineers.`,
      repo: "https://github.com/matkam7/CS3733-D18-teamF"
    },

    // SMAL PROJECTS
    // short descriptions are unused at the moment

    {
      title: "Escape Gallery",
      link: "escape-gallery",
      size: Sizes.Small,
      filters: ["PHP"],
      thumbnail: "/images/projects/escape_gallery.png",
      media: ["/images/projects/escape_gallery.png",
      "/images/projects/Escape Gallery/cracking_christmas_start.png"],
      longDescription: `Created a website on infnitefreeapp to host a collection
      of virtual escape rooms.`
    },
    {
      title: "Clue Bot",
      link: "clue-bot",
      size: Sizes.Small,
      filters: ["Python", "Qt"],
      thumbnail: "/images/projects/clue.png",
      media: ["/images/projects/Clue Bot/player_setup.png",
      "/images/projects/Clue Bot/cards_dealt.png",
      "/images/projects/Clue Bot/game_start.png",
      "/images/projects/Clue Bot/some_turns.png"],
      longDescription: `Created an online card to keep track of guesses in Clue.
      It keeps track of who has certain cards, as well as what guesses were made
      and who potentially revealed what cards. This will eventually say what the
      optimal guesses are to rule out the most number of options when making
      one's own guess.`
    },
    {
      title: "JARVIS",
      link: "jarvis",
      size: Sizes.Small,
      filters: ["Java", "Twilio"],
      thumbnail: "/images/projects/jarvis.png",
      media: [],
      longDescription: "I made a personal assistant in Java to perform various tasks for me."
    },
    {
      title: "WebGL",
      link: "webgl",
      size: Sizes.Small,
      thumbnail: "/images/projects/WebGL.png",
      filters: ["WebGL", "Graphics"],
      media: [],
      longDescription: "I implemented transformations, reflection, refraction in WebGL."
    },
    {
      title: "GTA 2D",
      link: "gta-2d",
      size: Sizes.Small,
      filters: ["Python", "GameDev"],
      thumbnail: "/images/projects/gta.png",
      media: [],
      longDescription: `Began working on a videogame that was intended to be a 2D version of
      GTA similar to StickRPG. This game is written in Python using pygame.
      The player can move around and steal money from banks. The player can
      shoot citizens with 3 different guns. When a gunshot is heard by
      surrounding citizens, they will call the police. A police officer is
      then dispatched to the location of the heard gunshot. The police will
      chase the player if they are in they are close enough to be seen. If a
      cop hears a different gunshot later, they will travel to that
      location. If a cop catches the player, they will go to jail and lose
      all their money. The player can kill citizens for more money. The
      player can also kill cops. I have many dreams for this game, but I
      began to run into hardware limitations and need to begin optimizing.`
    },
    {
      title: "The Curse of Demise",
      link: "the-curse-of-demise",
      size: Sizes.Small,
      filters: ["Unity", "C#", "GameDev"],
      thumbnail: "/images/projects/demise.png",
      media: ["/images/projects/The Curse of Demise/startup.png"],
      longDescription: `
      The Legend of Zelda has always been my favorite videogame franchise. I
      wanted to create a game where, rather than the triforce of power being
      inherently bad and the triforce of wisdom and courage being good, the
      triforce of wisdom was the malicious one and the triforce of power was
      good. This game intends to create Zelda as the intelligent villian who
      reincarnates Demise's curse. The protagonist is Ganondorf who will work
      towards overpowering the witty Zelda with the assistance of the
      courageous Link. Currently I have only implemented a 3D character
      model that collides with terrain. This is intended to be a low poly open world game
      created in Unity. I began to run into performance
      issues on my 2015 Macbook as I had the last time I used it
      with the Hot Pursuit hackathon game.`
    },
    {
      title: "Traffic Navigator",
      link: "traffic-navigator",
      size: Sizes.Small,
      filters: ["Java"],
      thumbnail: "/images/projects/traffic.png",
      media: ["/images/projects/Traffic Navigator/init.png",
      "/images/projects/Traffic Navigator/scenario_start.png",
      "/images/projects/Traffic Navigator/scenario_end.png"],
      longDescription:
      `An attempt at predictive GPS routing by knowing each cars route and where they
      will be at a certain point in time to prevent congestion and optimize everyone's route.`
    },
    {
      title: "Tic Tac Toe",
      link: "tic-tac-toe",
      size: Sizes.Small,
      filters: ["AI", "Javascript"],
      thumbnail: "/images/projects/tic_tac_toe.png",
      media: ["/images/projects/Tic Tac Toe/lose.png",
      "/images/projects/Tic Tac Toe/tie.png",
      "/images/projects/Tic Tac Toe/new_game.png"],
      longDescription: "I made an AI that always beats you in Tic Tac Toe. They're no fun to play against though... The best you can do is tie."
    },
    {
      title: "Anna Street Fighter",
      link: "anna-street-fighter",
      size: Sizes.Small,
      filters: ["Java", "GameDev", "Networking"],
      thumbnail: "/images/projects/smash_bros.jpeg",
      media: ["/images/projects/Anna Street Fighter/game_start.png",
      "/images/projects/Anna Street Fighter/main_menu.png"],
      longDescription: `Began a super smash brothers style game in Java with my
      college roomates. Two clients can connect to the same server IP address
      and move their characters around.`
    },
    {
      title: "Hot Pursuit",
      link: "hot-pursuit",
      size: Sizes.Small,
      filters: ["Unity", "C#", "GameDev"],
      thumbnail: "/images/projects/police.png",
      media: ["/images/projects/Hot Pursuit/start.png",
      "/images/projects/Hot Pursuit/cops_afar.png",
      "/images/projects/Hot Pursuit/game_over.png",
      "/images/projects/Hot Pursuit/main_menu.png"],
      longDescription:
      `For my first hackathon I wanted to make a videogame in Unity. I
      enjoyed the cop chases in Need for Speed Carbon, so I wanted to make
      something similar. This was my first time touching Unity and C#. The
      current game consists of the player driving away from cops. The
      players "heat" is how many cops have spawned in. Once a cop collides
      with the player, the game is over. I ran into performance issues when
      trying to add more assets on my humble 2015 Macbook, so I stopped
      developing it a few weeks after the hackathon ended.`
    },
    {
      title: "This website :)",
      link: "this-website",
      size: Sizes.Small,
      filters: ["React", "Javascript", "HTML", "CSS"],
      thumbnail: "/images/projects/this_website.png",
      media: ["/images/projects/this_website.png"],
      longDescription: "This website was created with React. I've used React before, but this may be the most I have worked with it."
    }

    // image annotator

    // bomberman

    // virtual memory

    // communal bathroom

    // kernel interception

  ];

  return (
      <Routes>
        <Route path="/" element={<ProjectHub projects={projects} />} />
        {projects.map(function(project, i){
          return <Route
          exact path={"/" + project.link}
          element={<ProjectPage project={project}/>}
          key={i} />;
        })}
        <Route path="/*" element={<ErrorPage message={"Invalid project link"} />} />
      </Routes>
  );
}

export default Projects;
