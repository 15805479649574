import React from "react";
import './Projects.css';
import LargeProject from "./LargeProject.js";
import SmallProject from "./SmallProject.js";
import Sizes from "./Enums.js";

function ProjectHub( { projects }) {

    let smallProjects = [];
    let largeProjects = [];
    for (let i = 0; i < projects.length; i++) {
        switch (projects[i].size) {
            case Sizes.Large:
                largeProjects.push(projects[i])
                break;
            case Sizes.Small:
                smallProjects.push(projects[i])
                break;
            default:
                console.log(projects[i].title + " project size not found");
        }
    } 

  return (
    <div id="projects-div">
      <h1 className="text-center lucida">Projects</h1>
      {/* <p className="text-center black">Filters:</p>
      <p className="text-center black">Graphics, C++, etc.</p> */}

      <div className="flex-grid" style={{gap: "20px"}}>

        {largeProjects.map(function(project, i){
            return <LargeProject
            title={project.title}
            link={project.link}
            filters={project.filters}
            image={project.thumbnail}
            description={project.shortDescription}
            key={"large"+i}
            />;
        })}

      </div>


      <br/>
      <br/>
      <h1 className="text-center lucida">Smaller Projects</h1>
      <p className="text-center black">"You're only as strong as your weakest work. Quality over quantity. Don't put your weak, day-long projects in your portfolio" Anyways... here's my weak projects :)</p>

      <div className="small-flex-grid" style={{gap: "20px"}}>

        {smallProjects.map(function(project, i){
                return <SmallProject
                title={project.title}
                link={project.link}
                filters={project.filters}
                image={project.thumbnail}
                key={"small"+i}
                />;
            })}

      </div>

    </div>
  );
}

export default ProjectHub;
