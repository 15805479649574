import React from "react";
import "./Projects.css";
import { Link } from 'react-router-dom';


function SmallProject({ title, link, filters = [], image="/images/build_icon.png" }) {

    let filterList = filters.map(function(filter){
        return <div className="badge"><p className="card-text"><small className="text-muted">{ filter }</small></p></div>;
        });

  return (
    <Link to={link} style={{ textDecoration:"none" }} className="card mb-3 flex-col-3 small-project-card">
        <div className="row no-gutters h-100">
            <div className="col-4 vertical-center">
                <img src={ image } className="card-img" alt="..."></img>
            </div>
            <div className="col-8">
                <div className="card-body">
                    <h5 className="card-title lucida white">{ title }</h5>

                    <div style={{display: "flex", gap:"10px", flexFlow:"wrap"}}>
                        { filterList }
                    </div>
                </div>
            </div>
        </div>
    </Link>
  );
}

export default SmallProject;
