import React from "react";
import "./TimelineItem.css";


function TimelineItem({ image, imageUrl, title, roles }) {

  return (
    <div id="timeline-item-div">

        <a href={imageUrl}>
          <img src={image}  alt="..." id="company-image"></img>
        </a>

        {title &&  <h3>{title}</h3>}


        {roles.map(function(role, i){
          return <>
            {role.jobTitle && <h4 className="sub-details">{role.jobTitle}</h4>}
            {role.date && <h4 className="sub-details">{role.date}</h4>}
            <p className="black">{role.description}</p>
          </>;
        })}


        <div>
            <div id="vertical-line"></div>
        </div>
    
    </div>
  );
}

export default TimelineItem;
