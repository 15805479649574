import React from "react";
import "./Projects.css";
import { Link } from 'react-router-dom';


function LargeProject({ title, link, description, filters = [], image = "/images/build_icon.png"}) {

    let filterList = filters.map(function(filter){
        return <div className="badge"><p className="card-text"><small className="text-muted">{ filter }</small></p></div>;
        });

  return (
        <Link to={link} style={{ textDecoration:"none" }} className="card mb-3 flex-col-4 large-project-card">
            <div className="row no-gutters h-100">
            <div className="col-6 vertical-center">
                <img src={ image } className="card-img" alt="..."></img>
            </div>
            <div className="col-6">
                <div className="card-body">
                <h5 className="card-title lucida white">{ title }</h5>
                <p className="card-text">{ description }</p>
                <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>

                <div style={{display: "flex", gap:"10px", flexFlow:"wrap"}}>
                    { filterList }
                </div>
                </div>
            </div>
            </div>
        </Link>
  );
}

export default LargeProject;
