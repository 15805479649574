import React from "react";
import { Link } from 'react-router-dom';
import "./Navigation.css";
import { Navbar, Nav, Container } from 'react-bootstrap';


function Navigation() {
  return (
    <>
        <Navbar collapseOnSelect fixed='top' expand='lg' bg='dark' variant='dark'>
            <Container> 
            <div style={{display:"flex"}}>
                <Link to='/' style={{ textDecoration: 'none' }}>
                    <h3 className="montserrat gradient-text">Peter Maida</h3>
                </Link>
            </div>
                <Navbar.Toggle aria-controls='responsive-navbar-nav'/>
                <Navbar.Collapse id='responsive-navbar-nav' className="justify-content-center justify-content-center">
                    <Nav className="montserrat mx-5 unorder">
                        <Nav.Link className="gradient-text gradient-text-highlight list-item" href="/">Home</Nav.Link>
                        <Nav.Link className="gradient-text gradient-text-highlight list-item" href="/skills">Skills</Nav.Link>
                        <Nav.Link className="gradient-text gradient-text-highlight list-item" href="/experience">Experience</Nav.Link>
                        <Nav.Link className="gradient-text gradient-text-highlight list-item" href="/projects">Projects</Nav.Link>
                        <Nav.Link className="gradient-text gradient-text-highlight list-item" href='/resume_dec_2022.pdf' target='_blank'>Resume</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>
  );
}

export default Navigation;
