import React from "react";
import TimelineItem from "./TimelineItem";
import "./Experience.css";


function Experience() {

  const timelineItems = [
    {
      image: "/images/experience/rtx.png",
      imageUrl: "https://www.rtx.com/",
      roles: [
        {
          jobTitle: "Software Engineer II",
          date: "January 2021 - Present",
          description: `I am working on a sea-based X-Band Radar (XBR) for mid-course missile defense that 
          is featured in the Netflix movie "Interceptor". 
          My work consists of implementing new features that were designed by system engineers to stay
          ahead of evolving threats, as well as maintaining the current software builds. 
          I apply the agile methodology through daily stand-ups, sprint planning, sprint demos, and sprint 
          retrospectives. Some of the features I worked on are: updating the radar GUI into QT,
          refactoring an advanced radar algorithm, and updating radar communications from TCP to UDP multicast. 
          Most of the radar software is written in ADA and C++, but I have also updated MATLAB, Java, Perl, and Python tools. 
          I authored, moderated, and reviewed code reviews in Collaborator and iTracker. 
          I managed stories in Jira and RTC, and tickets in iTracker. 
          I used and edited Jenkins jobs to build development environments. 
          I updated technical documentation in Confluence, DOORS, and MediaWiki.`
        },
        {
          jobTitle: "Graduate Intern",
          date: "June 2020 - December 2020",
          description: `As an intern, I created MATLAB integration tests to verify XBR radar simulation runs. 
          I also updated requirement tracing in DOORS.`
        }
      ]
    },
    {
      image: "/images/experience/insulet.png",
      imageUrl: "https://www.insulet.com/",
      roles: [
        {
          jobTitle: "Life-Cycle Engineering Intern",
          date: "June 2019 - August 2019",
          description: `I ported and automated unit tests from the previous Omnipod insulin management 
          product into VectorCAST software. I verified function control flow with VectorCAST.`
        }
      ]
    },
    {
      image: "/images/experience/gdms.png",
      imageUrl: "https://gdmissionsystems.com/",
      roles: [
        {
          jobTitle: "Software Engineering Intern",
          date: "June 2018 - July 2018",
          description: `I worked on a prototype for an embedded system designed to transfer an 
          encrypted bitstream from a keyloader device  to a military radio wirelessly. The prototype was simulated on 
          Raspberry Pis in a Linux environment using GPIO pins and signaling. Testing was performed 
          with oscilloscopes to assure the bitstream was being sent at the required frequency.`  
        }
      ]
    },
    {
      image:"/images/experience/wpi_side.png",
      imageUrl: "https://www.wpi.edu/",
      roles: [
        {
          jobTitle: "BS/MS Student",
          date: "August 2016 - December 2020",
          description: `I studied through the accelerated BS/MS program to 
          earn my Bachleor's and Master's in computer science in under five years. 
          My MQP capstone project was an IoT network security protocol to detect
          when a device was compromised to become part of a botnet.
          Over my schooling, I took a variety of courses in software
          engineering, operating systems, networks, algorithms, and cyber
          security. My Bachelor's degree has a concentration in cybersecurity. 
          I was the president and hackathon manager for the Association of Computing Machinery club, 
          and a member of the CyberSecurity Club.`
        }
      ]
    }
  ];


  return (
    <div id="experience-div" className="white-background">


        <h1>Experience</h1>


        {timelineItems.map(function(timelineItem, i) {
          return <TimelineItem
            key={i}
            image={timelineItem.image}
            imageUrl={timelineItem.imageUrl}
            roles={timelineItem.roles}/>;
        })}

{/* 
        <TimelineItem
        image="/images/experience/rtx.png"
        imageUrl="https://www.rtx.com/"
        roles={
          jobTitle: "Software Engineer II",
          date: "June 2020 - Present",
          description: "Software Engineer II. Graduate Intern. Created unit tests in MATLAB to verify radar requirements. <a>https://www.rtx.com/</a>"
        }
        />  
        
        
        <TimelineItem
        image="/images/experience/insulet.png"
        imageUrl="https://www.insulet.com/"
        jobTitle="Life-Cycle Engineering Intern"
        date="June 2019 - August 2019"
        description="Ported and automated unit tests from the previous product using VectorCAST software.
        Reviewed function control flow for correct behavior."/>

        <TimelineItem
        image="/images/experience/gdms.png"
        imageUrl="https://gdmissionsystems.com/"
        jobTitle="Software Engineering Intern"
        date="June 2018 - July 2018"
        description="Software Engineering Intern. Worked on a prototype for an embedded system designed to transfer an encrypted bitstream
        at a specific frequency. Programmed Raspberry Pi’s in a Linux environment using GPIO pins, signaling, and
        oscilloscope testing to simulate the embedded system and desired frequency."/>

        <TimelineItem
        image="/images/experience/wpi_side.png"
        imageUrl="https://www.wpi.edu/"
        jobTitle="BS/MS Student"
        date="August 2016 - December 2020"
        description="I studied computer science here. I graduated with my Bachleors of Science in Computer Science
        with a concentration in cybersecurity May 2020, and my Masters of Science in Computer Science in December 2020."/> */}

    </div>
  );
}

export default Experience;
