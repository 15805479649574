import React from 'react';
//import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Navigation from './Navigation';
import Home from './Home';
import Skills from './SkillsComponents/Skills';
import Experience from './ExperienceComponents/Experience';
import Projects from './ProjectsComponents/Projects';
import { BrowserRouter as Router, Route , Routes } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ErrorPage from './ErrorPage';

function App() {
  return (
    <Router>
      <div id="app">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Peter's Website</title>
        </Helmet>
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/skills" element={<Skills />} />
          <Route path="/experience" element={<Experience />} />
          <Route path="/projects/*" element={<Projects />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
