import React from "react";

function ErrorPage ({ message }) {

  return (
    <div style={{ minHeight:"90vh", padding:"30px", paddingTop:"10vh", textAlign:"center"}}>
        <h1>Error: Page does not exist</h1>
        { message && <h3>{ message }</h3> }
    </div>
  );
}

export default ErrorPage;
