import React from "react";
import "./Home.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'


function Home() {
  return (
    <div id="home-content">

        <div style={{alignContent:"center", justifyContent:"center", display:"flex", position:"fixed", zIndex:"0"}}>
          <img src="/images/boston.jpeg" alt="Boston cityscape" style={{height:"100vh"}}></img>
          {/* https://www.us.jll.com/en/views/boston-dynamic-tech-landscape */}
        </div>

        <div id="black-tint-overlay"></div>


        <div id="profile-photo-div">
            <div id="small-center">
              <img id="profile-photo" src="/images/wedding_edited.jpg" alt="me.jpg" style={{border:"3px solid gray"}}></img>
            </div>
            <div id="profile-text-div">
              <h1 id="name" className="montserrat gradient-text">Peter Maida</h1>
              <h3 className="montserrat gradient-text detail-text">Raytheon Software Engineer II</h3>
              <h3 className="montserrat gradient-text detail-text">Boston, MA</h3>
            </div>
        </div>

        <div id="about-me-overlay-div">
          <div id="about-me">
            
            <h2 className="montserrat off-white">Hello World!</h2>

            <p className="montserrat about-me-text">
              Welcome to my website! My name is Peter and I am a 
              software engineer who is currently working on a sea-based, missile 
              defense radar for Raytheon Technologies. Some of my past experience 
              includes: creating automated unit tests for insulin management systems 
              at Insulet Corporation, and prototyping IoT communication for a radio device 
              at General Dynamics Mission Systems.
            </p>

            <p className="montserrat about-me-text">
              My passion is to develop sustainable, secure, scalable code. I earned
              my Master's degree in computer science at WPI, as well as my Bachelor's degree
              in computer science with a concentration in cybersecurity at WPI. My
              MQP capstone project was an IoT network security protocol to detect
              when a device was compromised to become part of a botnet.
              Over my schooling, I took a variety of courses in software
              engineering, operating systems, networks, algorithms, and cyber
              security.
            </p>

            <p className="montserrat about-me-text">
              I currently reside in Boston, and have been in New England most of my
              life. Some of my hobbies include: playing videogames, hanging out with
              friends, reading, playing casual sports, working on personal projects, and
              learning new skills. I love to travel, and I am excited to go to Japan this year! 
              Additionally one of my goals for 2023 is to travel to more
              national parks including Mount Rainier and Acadia. A fun fact about me
              is that I studied abroad in Venice, helping a startup incubator get
              off the ground. To learn more about me, contact me!
            </p>

            <p className="montserrat about-me-text">
              Feel free to explore the rest of this website!
            </p>

          </div>

          <br/>
          <br/>
          <br/>

          <div id="icon-div">
            <a href="https://github.com/PeterMaidaRobot">
              <FontAwesomeIcon className="icon home-icon" icon={faGithub} size="6x"/>
            </a>
            <a href="https://www.linkedin.com/in/peter-maida-a4654b14a">
              <FontAwesomeIcon className="icon home-icon" icon={faLinkedin} size="6x"/>
            </a>
          </div>

        </div>

    </div>
  );
}

export default Home;
