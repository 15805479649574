import React from "react";
import "./Skills.css";
import "./Bubble.css";

import Bubble from './Bubble.js';


function Skills() {

  return (
    <div id="skills-div" className="white-background">

        <h1>Skills</h1>

        <ul id="color-key" class="list-group list-group-horizontal">
            <li className="list-group-item language">Language</li>
            <li className="list-group-item framework">Framework</li>
            <li className="list-group-item application">Application</li>
            <li className="list-group-item ci">CI</li>
        </ul>

        <Bubble
        skills={
        [
            // ********************* PROGRAMMING LANGUAGES *********************

            {
                name: "ADA",
                length: "3 yr", // Raytheon: June 2020-present
                class: "language"
            },
            {
                name: "C++",
                length: "2 yr", // Schoolwork, raytheon end of 2022 into 2023
                class: "language"
            },
            {
                name: "C",
                length: "2 yr", // schoolwork
                class: "language"
            },
            {
                name: "Python",
                length: "2 yr", // schoolwork, personal projects
                class: "language"
            },
            {
                name: "Java",
                length: "4 yr", // schoolwork, personal projects, raytheon, high school
                class: "language"
            },
            {
                name: "Perl",
                length: "6 mo", // Raytheon scripts
                class: "language"
            },
            {
                name: "C#",
                length: "1 mo", // Hot Pursuit and Curse of Demise
                class: "language"
            },
            {
                name: "Bash", // TODO what about Tcsh, zsh?
                length: "1 mo", // Raytheon scripts
                class: "language"
            },
            {
                name: "Rust",
                length: "3 mo", // MQP
                class: "language"
            },
            {
                name: "MATLAB",
                length: "2 yr", // Raytheon and internship
                class: "language"
            },
            {
                name: "VBA",
                length: "2 mo", // SATS script
                class: "language"
            },
            {
                name: "Javascript",
                length: "6 mo", // Webware classes, any personal webdev
                class: "language"
            },
            {
                name: "LaTex",
                length: "6 mo", // MQP, Resume, etc
                class: "language"
            },

            // ********************* FRAME WORKS *********************

            {
                name: "React",
                length: "2 mo", // portfolio site, 2 other website projects
                class: "framework"
            },
            {
                name: "Django",
                length: "1 mo", // Spotify App
                class: "framework"
            },
            {
                name: "Bootstrap",
                length: "3 mo", // this site, all sites, but I don't use it effectively
                class: "framework"
            },
            
            // ********************* APPLICATIONS, IDES, DEBUGGERS *********************
            
            {
                name: "Excel",
                length: "2 yr", // ofc
                class: "application"
            },
            {
                name: "Eclipse",
                length: "1 yr", // school and personal projects
                class: "application"
            }, 
            {
                name: "IntelliJ",
                length: "1 yr", // school and personal projects
                class: "application"
            },
            {
                name: "GPS", // GNAT Programming Studio
                length: "2.5 yr", // Raytheon
                class: "application"
            },
            {
                name: "gdb",
                length: "6 mo", // ofc
                class: "application"
            },

            // ********************* CI *********************

            {
                name: "Jenkins",
                length: "6 mo", // Used it all of raytheon, but havent configured much
                class: "ci"
            }

        ]
        }/>

    </div>
  );
}

export default Skills;
